





















import { Component, Vue } from 'vue-property-decorator';
import MenuBar from '@/components/MenuBar.vue';
import VFooter from '@/components/VFooter.vue';
import store from '@/store';
import {Getter} from 'vuex-class';

@Component({
  components: {
    MenuBar,
    VFooter,
  },
})
export default class App extends Vue {
  @Getter('isLoggedIn', { namespace: 'account' }) private isLoggedIn!: boolean;

  private tokenInitialized = false;

  private created() {
    store.dispatch('account/refreshToken')
      .finally(() => {
        if (this.isLoggedIn) {
          store.dispatch('account/userInfo');
          store.dispatch('account/subscriptionInfo');
        }
        this.tokenInitialized = true;
      });
  }
}

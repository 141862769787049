import { AccountState } from './types';

const state: AccountState = {
  firstName: '',
  lastName: '',
  newsLetter: false,
  organizationSubscription: '',
  userName: '',
  company: '',
  token: '',
  refreshToken: '',
  email: '',
  companyUser: false,
  ibanName: '',
  ibanNr: -1,
  kvkNr: -1,
  incassoAccepted: false,
  profileImage: '',
  daysTillEnd: -1,
  inlenerWebModule: false,
  caoTypes: new Set<string>([]),
};

export default state;

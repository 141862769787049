import { AccountState } from './types';

export const LOGIN = (state: AccountState, payload: {
    userName: string,
    token: string,
    refreshToken: string,
  }) => {
  state.userName = payload.userName;
  state.token = payload.token;
  state.refreshToken = payload.refreshToken;
};

export const LOGOUT = (state: AccountState) => {
  state.userName = '';
  state.token = '';
  state.refreshToken = '';
  state.organizationSubscription = '';
  state.profileImage = '';
  state.caoTypes = new Set<string>([]);
};

export const SET_SUBSCRIPTION_INFO = (
  state: AccountState, payload: {
    companyName: string,
    daysTillEnd: number;
    ibanName: string;
    ibanNr: number;
    incassoAccepted: boolean;
    kvkNr: number,
    type: string,
    inlenerWebModule: boolean,
    caosTool: string[],
  }) => {
  state.company = payload.companyName;
  state.kvkNr = payload.kvkNr;
  state.ibanNr = payload.ibanNr;
  state.ibanName = payload.ibanName;
  state.incassoAccepted = payload.incassoAccepted;
  state.daysTillEnd = payload.daysTillEnd;
  state.organizationSubscription = payload.type;
  state.inlenerWebModule = payload.inlenerWebModule;
  state.caoTypes = new Set(payload.caosTool);
};

export const SET_USER_INFO = (
  state: AccountState, payload: {
    newsLetter: boolean,
    firstName: string,
    lastName: string,
    email: string,
    companyUser: boolean;
    organizationSubscription: string;
  }) => {
    state.newsLetter = payload.newsLetter;
    state.firstName = payload.firstName;
    state.lastName = payload.lastName;
    state.email = payload.email;
    state.organizationSubscription = payload.organizationSubscription;
    state.companyUser = payload.companyUser;
};

export const SET_PROFILE_IMAGE = (state: AccountState, profileImage: string) => state.profileImage = profileImage;
